body,
p,
div {
  margin: 0;
  font-family: "MyCustomFont", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "MyCustomFont";
}
@font-face {
  font-family: "MyCustomFont";
  src: url("./assets/fonts/Manrope-Bold.ttf") format("ttf"),
    url("./assets/fonts/Manrope-Medium.ttf") format("ttf"),
    url("./assets//fonts/Manrope-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  overflow: -moz-scrollbars-none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer 10+ */
  background-color: #191919; /*after adding suspense need to change bg of the app */
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none; /* For Webkit browsers (Chrome, Safari) */
}
