.menu-screen {
  padding: 20px;
  margin: 20px;
}

.rows-section {
  margin-top: 20px;
}

.slider-section {
  margin-bottom: 20px;
  width: 90vw;
}

.rows-section {
  margin-top: 20px;
}
.row-title {
  color: white;
  padding: 30px 0px 10px 20px;
}

.rows-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 10px;
  gap: 10px;
  margin-top: 10px;
  width: 100vw;
  height: 500px;
  background-color: black;
}

.row-item {
  flex: 0 0 auto;

  flex-direction: row;
  align-items: center;
  text-align: center;

  padding: 10px;
  border-radius: 8px;
}

.slider-description {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}
.slider-container {
  height: 60vh;
  width: 100vw;

  @media (max-width: 1200px) {
    height: 300px;
  }
}
.slick-dots li button:before {
  font-size: 0px;
}

.row-item {
  flex: 0 0 auto;
  flex: 1 1 300px; /* Grow to fill available space but maintain a minimum width */
  max-width: 400px; /* Prevent items from growing too large */
  flex-direction: row;
  align-items: center;
  text-align: center;

  padding: 10px;
  border-radius: 8px;
}

.slider-description {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
}

.gradient-container {
  position: absolute; /* or relative, depending on your layout needs */
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; /* or the height you need */
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  z-index: -1; /* ensures it is behind other content */
}

.card {
  width: 347px;
  height: 243px;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: transform 0.3s;
  border: none;
  object-fit: "cover";
}
.custom-caption {
  position: static; /* Removes the absolute positioning */
  text-align: left; /* Aligns text to the left */
  margin-top: 20px; /* Adds margin above the caption */
  color: white; /* Ensures the text is white */
}

.custom-caption h3 {
  font-size: 24px; /* Adjust font size as needed */
  margin-bottom: 10px; /* Adjust margin as needed */
}

.custom-caption p {
  font-size: 16px; /* Adjust font size as needed */
  margin-bottom: 0; /* Remove margin below the paragraph */
}

.carousel-caption {
  position: relative; /* Ensure caption is positioned above the gradient */
  z-index: 2; /* Set z-index to be above the gradient */
  text-align: left; /* Align text to the left */
  color: white; /* Set caption text color */
}

.gradient-slider {
  /* position:relative; */
  /* height: 500px; */
  /* width: 100%; */
  /* overflow: hidden; Ensure no overflow */
  /* margin-top: -500px; */
  /* z-index: 2; */
  /* background: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)); */
}
.titleText {
  position: absolute;
  z-index: 22;
  color: white;
  font-size: 16px;
  margin-top: 2.5%;
  margin-left: 5%;

  font-weight: normal;
}

.hover .titleText {
  display: block; /* Visible on hover */
}

.gradient-slider::before {
  content: "";
  position: absolute;

  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1; /* Ensure gradient is above the image */
}
/* Menuscreen.css */

.menu-screen {
  background-color: green; /* Black background */
  color: #fff; /* White text color */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-container {
  width: 100%;
  max-width: 100vw;
  position: relative;
  /* background-color: #191919; */
  /* background-image: linear-gradient(to right, #000000, #00000080),
    linear-gradient(to bottom, #000000, #00000800); */
}

.carousel-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  width: 70vw;
  margin-top: 0%;
  transition: transform 0.3s, box-shadow 0.3s; /* Include box-shadow transition */
  /* box-shadow: 0 -2px 10px rgba(0, 0, 0, 1); */
  /* background-color: #191919; */
  @media (max-width: 1200px) {
    height: 300px;
  }
}

.carousel-caption {
  position: absolute; /* Position it absolutely within the carousel item */
  left: 20px; /* Space from the left edge */
  top: 50%; /* Start at the middle */
  transform: translateY(-50%); /* Center vertically */
  z-index: 2; /* Ensure it appears above other elements */
  color: white; /* Make sure text is white */
  /* Optional: add a semi-transparent background */
  padding: 20px; /* Optional: add some padding */
}

.carousel-caption h3,
.carousel-caption p {
  margin: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
}

.details-button {
  background-color: #28a745; /* Green button */
  border: none;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.item-container {
  display: flex;
  gap: 35px;
}
.card:hover {
  transform: scale(1.05);
  transform: translateX(10px);
}
@media (max-width: 1200px) {
  .row-item {
    flex: 1 1 45%; /* Adjust to 2 items per row */
  }
}

@media (max-width: 768px) {
  .row-item {
    flex: 1 1 100%; /* Stack items vertically */
  }
}
.carousel-background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
}
.slick-dots {
  /* Ensure dots are displayed */
  justify-content: center; /* Center the dots */
  /* Space above the dots */
  display: block;
  position: absolute;
  bottom: -6%;
  @media (max-width: 900px) {
    margin-top: -10vh;
  }
}

.slick-dots li button:before {
  color: white !important;
  opacity: 1 !important;
  font-size: 9px !important;
}

.slick-dots li.slick-active button:before {
  color: red !important;
  opacity: 1 !important;
  font-size: 10px !important;
}

.slick-dots li {
  margin: 0 5px; /* Space between dots */
  margin-top: -65vh;
}

.slick-dots li button {
  background: rgb(221, 221, 221); /* Default dot color */
  border: none; /* No border */
  border-radius: 50%;
  width: auto; /* Dot width */
  height: 5px;
}

/* .slick-dots li.slick-active button {
  color: red; 
} */
.slick-prev,
.slick-next {
  display: none !important; /* Forcefully hide arrows */
}

.sectionContainer {
  /* background-color: #191919; */
}
.slick-slider .slick-list {
  height: 70vh;
  /* background-color: #191919; */
  transition: transform 0.3s, box-shadow 0.3s; /* Include box-shadow transition */
  /* box-shadow: 0 -2px 10px rgba(0, 0, 0, 1); */
  @media (max-width: 1200px) {
    height: 300px;
  }
}
.slick-slider {
  /* background: linear-gradient(135deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)); */
}
