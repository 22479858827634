/* Full-screen overlay */
.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* Ensures the overlay is on top of other content */
}

/* Centered loader */
.dot-loader {
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styling for the dots */
.dot-item {
  width: 10px;
  height: 10px;
  background-color: #3498db;
  border-radius: 50%;
  position: absolute;
  animation: move 2s infinite;
}

/* Animation delay for the dots */
#dot-one {
  animation-delay: 0s;
}

#dot-two {
  animation-delay: 0.5s;
}

#dot-three {
  animation-delay: 1s;
}

#dot-four {
  animation-delay: 1.5s;
}

/* Keyframe animation for the dots */
@keyframes move {
  0%,
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
  25% {
    transform: translate(40px, 0);
    opacity: 0.5;
  }
  50% {
    transform: translate(40px, 40px);
    opacity: 0.7;
  }
  75% {
    transform: translate(0, 40px);
    opacity: 1;
  }
}
